import PostCoLogo from "./PostCoLogo";
import { DefaultShopSlugLoaderResponse } from "~/routes/_shopSlug/types";
import { DefaultShopIdLoaderResponse } from "~/routes/shops/$shopId/types";
import { useLoaderData } from "@remix-run/react";
import { cn } from "@/lib/utils";

type BrandingFooterLoaderData = (DefaultShopSlugLoaderResponse | DefaultShopIdLoaderResponse) & {
  translations?: {
    poweredBy?: string;
  };
};

export default function BrandingFooter({ className }: { className?: string }) {
  const { shopData, translations } = useLoaderData<BrandingFooterLoaderData>();
  const poweredBy = translations?.poweredBy;

  if (shopData.whiteLabel) return null;

  if (!poweredBy) throw new Error("'translations.poweredBy' key is missing from the loader data");

  return (
    <div className={cn("absolute w-full bottom-0 py-3 flex items-center justify-center bg-glass-light", className)}>
      <p className="text-zinc-700 mb-1 text-sm font-light text-center pr-1">{poweredBy}</p>
      <a href="https://postco.co" rel="noopener noreferrer" target="_blank" className="flex justify-center">
        <PostCoLogo />
      </a>
    </div>
  );
}
