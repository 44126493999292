function PostCoLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64px" height="22px" viewBox="0 0 128 44" version="1.1">
      <title>PostCo-Logo</title>
      <desc>Created with Sketch.</desc>
      <defs>
        <linearGradient x1="27.1015931%" y1="25.4922229%" x2="100%" y2="97.6912311%" id="linearGradient-1">
          <stop stopColor="#4F4872" offset="0%"></stop>
          <stop stopColor="#415672" offset="100%"></stop>
        </linearGradient>
      </defs>
      <g id="Final" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="PostCo-Landing-Page" transform="translate(-330.000000, -28.000000)">
          <g id="Group-16" transform="translate(0.000000, -1.000000)">
            <g id="Group">
              <g id="Header" transform="translate(0.000000, 21.000000)">
                <g id="PostCo-Logo" transform="translate(330.000000, 8.000000)">
                  <polygon
                    id="Fill-1"
                    fill="#393836"
                    points="15.8130704 14.9777572 22.4109011 11.8441509 15.8130704 8.94169196 15.8130704 8.94169196 9.2152396 11.8441509"
                  ></polygon>
                  <polygon
                    id="Fill-2"
                    fill="#393836"
                    points="15.8130704 17.1963762 8.91475604 13.9385344 8.91475604 19.8676878 15.8130704 23.869458 15.8130704 23.869458 22.7113847 19.8676878 22.7113847 13.9385344"
                  ></polygon>
                  <g id="PostCo" transform="translate(0.000000, 0.139134)">
                    <path
                      d="M47.4626094,17.638724 C47.9394423,17.0992151 48.1783514,16.3117301 48.1783514,15.2752791 C48.1783514,14.238828 47.8675233,13.5023241 47.244882,13.0657673 C46.6227332,12.6287156 45.6557673,12.4104373 44.342999,12.4104373 L42.0676981,12.4104373 L42.0676981,18.4479874 L44.7523462,18.4479874 C46.0818628,18.4479874 46.9852839,18.178233 47.4626094,17.638724 Z M50.3901074,10.5736322 C51.642779,11.6442356 52.2693611,13.2885004 52.2693611,15.5064265 C52.2693611,17.7248475 51.6255382,19.3473339 50.3388774,20.3748756 C49.0517241,21.4024174 47.0872513,21.9161883 44.4454589,21.9161883 L42.0676981,21.9161883 L42.0676981,26.9261967 L38.0786557,26.9261967 L38.0786557,8.96797445 L44.394229,8.96797445 C47.1384812,8.96797445 49.1369432,9.50352366 50.3901074,10.5736322 Z"
                      id="Fill-3"
                      fill="#555555"
                    ></path>
                    <path
                      d="M57.8946596,20.0149391 C57.8946596,21.1459279 58.2138618,22.0660628 58.8527589,22.7773237 C59.4921485,23.4875946 60.3103504,23.8434725 61.3078573,23.8434725 C62.3048716,23.8434725 63.1230735,23.4875946 63.7619705,22.7773237 C64.4018527,22.0660628 64.721055,21.1459279 64.721055,20.0149391 C64.721055,18.8849401 64.4018527,17.9598556 63.7619705,17.2406754 C63.1230735,16.5210002 62.3048716,16.1611626 61.3078573,16.1611626 C60.3103504,16.1611626 59.4921485,16.5210002 58.8527589,17.2406754 C58.2138618,17.9598556 57.8946596,18.8849401 57.8946596,20.0149391 M68.5820224,20.0149391 C68.5820224,22.0190414 67.8918954,23.7063679 66.5111488,25.0764236 C65.1299096,26.4469742 63.3954791,27.1310121 61.3078573,27.1310121 C59.2197429,27.1310121 57.4853124,26.4469742 56.1045658,25.0764236 C54.7238192,23.7063679 54.0331996,22.0190414 54.0331996,20.0149391 C54.0331996,18.0113317 54.7238192,16.3200455 56.1045658,14.9410805 C57.4853124,13.5626105 59.2197429,12.873128 61.3078573,12.873128 C63.3954791,12.873128 65.1299096,13.5626105 66.5111488,14.9410805 C67.8918954,16.3200455 68.5820224,18.0113317 68.5820224,20.0149391"
                      id="Fill-5"
                      fill="#555555"
                    ></path>
                    <path
                      d="M81.6469982,22.6228954 C81.6469982,23.9840418 81.1489836,25.0764236 80.1514768,25.8985559 C79.1544625,26.7206883 77.8889833,27.1310121 76.3550394,27.1310121 C75.3324101,27.1310121 74.2664324,26.9473811 73.158584,26.5791291 C72.0507356,26.2108771 71.0532287,25.6669136 70.1670485,24.9477334 L71.8546824,22.5328123 C73.2861664,23.6459825 74.8117362,24.2028151 76.4313918,24.2028151 C76.9254656,24.2028151 77.3170794,24.1043176 77.607711,23.9073226 C77.8973575,23.7103276 78.0421807,23.4494825 78.0421807,23.1237973 C78.0421807,22.7981121 77.8416941,22.4986599 77.4412136,22.2244508 C77.0402404,21.9507366 76.4614401,21.6938512 75.7028423,21.4537945 C74.9437519,21.2142327 74.364459,21.0083284 73.9634858,20.8375664 C73.5630053,20.6663095 73.1078466,20.4178384 72.5960394,20.0921532 C71.5566618,19.4412778 71.036973,18.4780811 71.036973,17.2020683 C71.036973,15.9265505 71.5477951,14.8856447 72.5704244,14.0803411 C73.5935463,13.2755324 74.9141961,12.873128 76.5333592,12.873128 C78.1530148,12.873128 79.7716852,13.4215462 81.3913409,14.5173928 L79.9598569,17.0862471 C78.7658042,16.2641147 77.5776627,15.853296 76.3929693,15.853296 C75.2082759,15.853296 74.6161755,16.1784863 74.6161755,16.8293617 C74.6161755,17.1891993 74.803362,17.4718228 75.1787202,17.6772321 C75.5535857,17.8826415 76.1757345,18.1226982 77.0451664,18.3964123 C77.9141057,18.6711164 78.5323137,18.8804855 78.8988051,19.0260044 C79.264804,19.1715233 79.7041996,19.398711 80.2160068,19.7070725 C81.1696727,20.2891482 81.6469982,21.2612541 81.6469982,22.6228954"
                      id="Fill-7"
                      fill="#555555"
                    ></path>
                    <path
                      d="M89.0619472,15.8787866 L89.0619472,22.07027 C89.0619472,22.6018595 89.1979037,23.0166379 89.4708019,23.3165851 C89.7437001,23.6160373 90.0673356,23.7660109 90.4426938,23.7660109 C91.1584358,23.7660109 91.7633436,23.4408206 92.2574174,22.7894502 L93.6898866,25.4874897 C92.4968191,26.5833363 91.2392215,27.1312595 89.9185716,27.1312595 C88.5969366,27.1312595 87.4802215,26.6947028 86.5684263,25.8210943 C85.6571237,24.9479808 85.2009798,23.7571015 85.2009798,22.2504363 L85.2009798,15.8787866 L83.5901909,15.8787866 L83.5901909,13.1045229 L85.2009798,13.1045229 L85.2009798,8.96812294 L89.0619472,8.96812294 L89.0619472,13.1045229 L92.3854924,13.1045229 L92.3854924,15.8787866 L89.0619472,15.8787866 Z"
                      id="Fill-9"
                      fill="#555555"
                    ></path>
                    <path
                      d="M104.555126,24.1769286 C105.594503,24.1769286 106.490043,24.0017119 107.239774,23.6507836 C107.989998,23.2998554 108.77421,22.7385681 109.592412,21.9674169 L111.535704,23.9715192 C109.643642,26.0785737 107.346175,27.1313585 104.645271,27.1313585 C101.942889,27.1313585 99.7010848,26.2577501 97.9208429,24.5110281 C96.1391231,22.7643062 95.2485096,20.5547944 95.2485096,17.8829879 C95.2485096,15.2111814 96.156364,12.984346 97.9715802,11.2029767 C99.7872891,9.42210237 102.079831,8.53117022 104.849698,8.53117022 C107.619565,8.53117022 109.933781,9.55871196 111.790868,11.6142904 L109.874177,13.72085 C109.021001,12.8987176 108.216099,12.3250563 107.457501,11.9993711 C106.698904,11.6741808 105.808783,11.5113382 104.785661,11.5113382 C102.978326,11.5113382 101.461623,12.0983635 100.234566,13.2714242 C99.007017,14.4444848 98.3932424,15.9432307 98.3932424,17.7671668 C98.3932424,19.5911028 99.0030763,21.1155867 100.221759,22.3401235 C101.440441,23.5651552 102.884733,24.1769286 104.555126,24.1769286"
                      id="Fill-11"
                      fill="#555555"
                    ></path>
                    <path
                      d="M117.607984,23.3165356 C118.417812,24.1475773 119.444382,24.5623557 120.688679,24.5623557 C121.932484,24.5623557 122.960039,24.1475773 123.77036,23.3165356 C124.579202,22.4854939 124.984609,21.4114257 124.984609,20.0923512 C124.984609,18.7737716 124.579202,17.6987135 123.77036,16.8676718 C122.960039,16.03762 121.932484,15.6223466 120.688679,15.6223466 C119.444382,15.6223466 118.417812,16.03762 117.607984,16.8676718 C116.797663,17.6987135 116.393242,18.7737716 116.393242,20.0923512 C116.393242,21.4114257 116.797663,22.4854939 117.607984,23.3165356 M125.802811,25.0889956 C124.438812,26.4506369 122.73443,27.1312101 120.688679,27.1312101 C118.643913,27.1312101 116.939038,26.4506369 115.57504,25.0889956 C114.211042,23.7273543 113.530274,22.0618062 113.530274,20.0923512 C113.530274,18.1228962 114.211042,16.4568531 115.57504,15.0952118 C116.939038,13.7335705 118.643913,13.0529973 120.688679,13.0529973 C122.73443,13.0529973 124.438812,13.7335705 125.802811,15.0952118 C127.165824,16.4568531 127.848069,18.1228962 127.848069,20.0923512 C127.848069,22.0618062 127.165824,23.7273543 125.802811,25.0889956"
                      id="Fill-13"
                      fill="#555555"
                    ></path>
                    <path
                      d="M25.6267156,21.4198401 L15.8132181,27.1133914 L5.99922812,21.4198401 L5.99922812,9.9218658 L15.8132181,5.60480461 L25.6267156,9.9218658 L25.6267156,21.4198401 Z M15.8132181,0.0156903049 C7.09377642,0.0156903049 -9.85191982e-05,7.1436424 -9.85191982e-05,15.9044751 C-9.85191982e-05,17.6873293 0.298414654,19.4003939 0.839777654,21.0015969 C1.2151358,22.1127873 1.70576142,23.1705217 2.30475815,24.1564866 C2.71459802,24.8316152 3.17468268,25.4725914 3.67713059,26.0769404 L15.8132181,43.0620667 L20.8342491,36.033602 L17.7190721,31.6739741 C21.4682202,31.2191037 24.81541,29.442189 27.2872567,26.8203737 C27.514836,26.5793271 27.7365042,26.3328359 27.9488131,26.0769404 C28.451261,25.4725914 28.9113457,24.8316152 29.3211856,24.1559917 C29.9201823,23.1705217 30.4108079,22.1127873 30.7866586,21.0015969 C31.3280216,19.4003939 31.6260422,17.6873293 31.6260422,15.9044751 C31.6260422,7.1436424 24.5321673,0.0156903049 15.8132181,0.0156903049 L15.8132181,0.0156903049 Z"
                      id="Fill-46"
                      fill="#FDC656"
                    ></path>
                    <polygon
                      id="Fill-47"
                      fill="#595959"
                      points="22.4111474 11.7049675 15.8133167 8.80250856 15.8128241 8.80250856 15.8128241 14.8385738"
                    ></polygon>
                    <polygon
                      id="Fill-48"
                      fill="#595959"
                      points="8.9148053 13.7995985 8.9148053 19.7287519 15.8131196 23.7300271 15.8131196 20.8611344 15.8131196 17.0574403"
                    ></polygon>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PostCoLogo;
